export const subjects = [
	"BE",
	"CEE",
	"CHEM",
	"CHEME",
	"CS",
	"ECON",
	"EE",
	"ENGR",
	"LS",
	"MAE",
	"MATH",
	"MGMT",
	"MSE",
	"PHYSICS",
	"STATS",
];

export const testTypes = ["Homework", "Quiz", "Midterm", "Final"];

export const testNums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const quarters = ["Fall", "Winter", "Spring", "Summer"];
